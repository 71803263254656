// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-haefter-diasshow-til-born-om-metakognitiv-terapi-js": () => import("./../../../src/pages/haefter/diasshow-til-børn-om-metakognitiv-terapi.js" /* webpackChunkName: "component---src-pages-haefter-diasshow-til-born-om-metakognitiv-terapi-js" */),
  "component---src-pages-haefter-diasshow-til-unge-med-stress-js": () => import("./../../../src/pages/haefter/diasshow-til-unge-med-stress.js" /* webpackChunkName: "component---src-pages-haefter-diasshow-til-unge-med-stress-js" */),
  "component---src-pages-udgivelser-index-js": () => import("./../../../src/pages/udgivelser/index.js" /* webpackChunkName: "component---src-pages-udgivelser-index-js" */),
  "component---src-templates-blog-index-page-jsx": () => import("./../../../src/templates/blog-index-page.jsx" /* webpackChunkName: "component---src-templates-blog-index-page-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-book-page-jsx": () => import("./../../../src/templates/book-page.jsx" /* webpackChunkName: "component---src-templates-book-page-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/contact-page.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-profile-page-jsx": () => import("./../../../src/templates/profile-page.jsx" /* webpackChunkName: "component---src-templates-profile-page-jsx" */),
  "component---src-templates-standard-page-jsx": () => import("./../../../src/templates/standard-page.jsx" /* webpackChunkName: "component---src-templates-standard-page-jsx" */)
}

